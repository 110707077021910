import React from "react";
import useT  from '../../Traduction';

function ResetButton({ setExtension, setLines, setFileSeparator, setHasHeader, setFileInformationsCompleted, setFormatName, setFormatAndSupplierNameCompleted, setUnitPrice, setFileConfirmed, setFixedFieldSize, setFieldMapping, setFileOutput, setFieldMappingConfirmed, setFileOutputConfirmed, setFileEncoding, setSupplier, setQuoteCharacter, setHeadersRows, setFixedFieldSizeColumnObject, setCountry, setLanguage, setMeasurementUnitConfirmed, setMeasurementUnitFields, setLengthUnit, setHeightUnit, setVolumeUnit, setWeightUnit, setWidthUnit, setSkipRows, setSkipEndRows  }) {
    const t = useT();
    const handleReset = () => {
        setExtension("");
        setLines([]);
        setFileSeparator("");
        setHasHeader(true);
        setFileInformationsCompleted(false);
        setFormatName("");
        setFormatAndSupplierNameCompleted(false);
        setUnitPrice("");
        setFileConfirmed(false);
        setFixedFieldSize(false);
        setFieldMapping([]);
        setFileOutput([]);
        setFieldMappingConfirmed(false);
        setFileOutputConfirmed(false);
        setFileEncoding("");
        setSupplier("");
        setQuoteCharacter("");
        setHeadersRows([]);
        setFixedFieldSizeColumnObject([]);
        setSupplier("");
        setCountry("FR");
        setLanguage("FR");
        setMeasurementUnitConfirmed(false);
        setMeasurementUnitFields([]);
        setLengthUnit("");
        setHeightUnit("");
        setVolumeUnit("");
        setWeightUnit("");
        setWidthUnit("");
        setSkipEndRows(0);
        setSkipRows(0);

    };
    return (
        <div className="d-flex justify-content-center">
            <button type="submit" className="btn mt-3" onClick={handleReset}>{t("Reset_creation")}</button>
        </div>
    );
}

export default ResetButton;
