import React, { useState } from "react";
import useT  from '../../Traduction';
import * as XLSX from 'xlsx'
import Papa from "papaparse";
import SettingFixedWidthFields from "./SettingFixedWidthFields";
const chardet = require('chardet');
const Buffer = require('buffer').Buffer;
const { FixedWidthParser } = require('fixed-width-parser');

function FileRepository({ inputFileRepositoryAcceptExtension, setLines, setFileEncoding, setQuoteCharacter, setLineEnding, setFileName, extension, setHeadersRows, setFileSeparator, hasHeader, fileSeparator, setHasHeader, fixedFieldSize, fixedFieldSizeColumnObject, setFixedFieldSizeColumnObject, formatName ,skipRows, setSkipRows, fileEncoding }) {
  const t = useT();
  const [errorMessage, setErrorMessage] = useState("");
  const [inProgressMessage, setInProgressMessage] = useState("");
  const [confirmFixedFieldSettings, setConfirmFixedFieldSettings] = useState(false);

  const delimiter = fileSeparator ? fileSeparator: undefined;
  const encoding = fileEncoding ? fileEncoding : undefined;

  const checkHeaders = (headers) => {
    // Vérifier si tous les en-têtes sont vides
    const allHeadersAreEmpty = headers.every(header => header === "");
  
    // Vérifier si doublons
    const hasDuplicateHeaders = !allHeadersAreEmpty && headers.length > new Set(headers.filter(header => header !== "")).size;
  
    // Si doublons, return true, sinon false
    return hasDuplicateHeaders;
  };

  const handleProcessFile = (content, hasDuplicateHeaders) => {
    setInProgressMessage("")
    handleSetQuote(content)
    // Si des lignes sont à ignorer => skipRows + headers à false
    if (skipRows !== 0) {
      Papa.parse(content, {
        header: false,
        skipEmptyLines: true,
        delimiter: delimiter,
        encoding: encoding,
        complete: function (results) {
          const valuesArray = results.data.map(d => Object.values(d));
          const headersArray = Object.keys(results.data[0]);
          const lineBreak = results.meta.linebreak === "\r\n" ? "\n" : results.linebreak;
          if (!delimiter) {
            setFileSeparator(results.meta.delimiter)
          }
          else {
            setFileSeparator(delimiter)
          }
          setHasHeader(false)
          setHeadersRows(headersArray);
          setLineEnding(lineBreak);
          setInProgressMessage("") 
          if (extension === "TXT" && fixedFieldSize === true && hasHeader === false) {
            setLines(valuesArray.slice(1, 11));
          } else {
            setLines(valuesArray.slice(0, 10));
          }
        },
      })
    } else {
          if ( hasDuplicateHeaders ) {
            Papa.parse(content, {
              header: !hasDuplicateHeaders,
              skipEmptyLines: true,
              skipFirstNLines: 1,
              delimiter: delimiter,
              encoding: encoding,
              complete: function (results) {
                const valuesArray = results.data.map(d => Object.values(d));
                const headersArray = Object.keys(results.data[0]);
                const lineBreak = results.meta.linebreak === "\r\n" ? "\n" : results.meta.linebreak;
                setLineEnding(lineBreak);
                setHeadersRows(headersArray);
                setInProgressMessage("") 
                if (!delimiter) {
                  setFileSeparator(results.meta.delimiter)
                }
                else {
                  setFileSeparator(delimiter)
                }
                if (extension === "TXT" && fixedFieldSize === true && hasHeader === false) {
                  setLines(valuesArray.slice(1, 11));
                } else {
                  setLines(valuesArray.slice(0, 10));
                }
              },
              })
          } else {
            Papa.parse(content, {
              header: !hasDuplicateHeaders,
              skipEmptyLines: true,
              delimiter: delimiter,
              encoding: encoding,
              complete: function (results) {
                const valuesArray = results.data.map(d => Object.values(d));
                const headersArray = Object.keys(results.data[0]);
                const lineBreak = results.meta.linebreak === "\r\n" ? "\n" : results.meta.linebreak;
                setHeadersRows(headersArray);
                setLineEnding(lineBreak);
                setInProgressMessage("") 
                if (!delimiter) {
                  setFileSeparator(results.meta.delimiter)
                }
                else {
                  setFileSeparator(delimiter)
                }
              if (extension === "TXT" && fixedFieldSize === true && hasHeader === false) {
                setLines(valuesArray.slice(1, 11));
              } else {
                setLines(valuesArray.slice(0, 10));
              }
            },
            })
          }
    };
  } 

  const handleSetQuote = (content) => {
    const countDoubleQuote = content.split('"').length - 1;
    const countDoubleQuoteGlued = content.split('""').length - 1;
    const countSingleQuote = content.split("'").length - 1;
    const countSingleQuoteGlued = content.split("''").length - 1;
    const glueDoubleQuote = countDoubleQuoteGlued * 2;
    const glueSimpleQuote = countSingleQuoteGlued * 2;
    const doubleQuote = countDoubleQuote - glueDoubleQuote;
    const simpleQuote = countSingleQuote - glueSimpleQuote;
    
    setQuoteCharacter(doubleQuote > simpleQuote ? '"' : (doubleQuote < simpleQuote ? "'" : ""));
  }


  const handleInputFileRepository = async (event) => {
    setInProgressMessage(t("File_being_processed"));
    const file = event.target.files[0];
    setFileName(file.name);
  
    const readerEncoding = new FileReader();
    readerEncoding.onload = async () => {
      const fileBuffer = Buffer.from(readerEncoding.result);
      const encodingValue = chardet.detect(fileBuffer);
      if (!fileEncoding) {
        setFileEncoding(encodingValue);
      }

      try {
        let content;
        if (extension === "CSV") {
          const readerCsv = new FileReader();
          readerCsv.onload = (e) => {
            content = e.target.result;
            const firstLine = content.split('\n')[0].trim();
            const headers = firstLine.split(delimiter !== "" ? delimiter : ",");
            // check les headers
            const hasDuplicateHeaders = checkHeaders(headers);
            // si des doublons sont trouvés, ignorer la premiere ligne du fichier qui était celle des headers
            if (hasDuplicateHeaders) {
              const contentWithoutFirsLine = content.replace(firstLine + '\n', '');
              setHasHeader(false)
              setSkipRows(1)
              handleProcessFile(contentWithoutFirsLine, hasDuplicateHeaders);
            } else {
              // Si un nombre de lignes à ignorer
              if (skipRows !== 0) {
                const lines = content.replace(/\r\n/g, '').split('\n');
                const contentWithoutFirstLines = lines.slice(skipRows).join('\n');
                handleProcessFile(contentWithoutFirstLines, hasDuplicateHeaders);
              } else {
                handleProcessFile(content, hasDuplicateHeaders);
              }
            }
          };
          if (!fileEncoding) {
            readerCsv.readAsText(file, encodingValue);
          }
          else {
            readerCsv.readAsText(file, fileEncoding);
          }
        } else if (extension === "TXT") {
          const readerTxt = new FileReader();
          readerTxt.onload = (e) => {
            content = e.target.result;
            if (fixedFieldSize) {
              const fixedFieldSizeColumnList = Object.values(fixedFieldSizeColumnObject);
              const fixedWidthParser = new FixedWidthParser(fixedFieldSizeColumnList);
              const fixedWidthValueParse = fixedWidthParser.parse(content);
              content = Papa.unparse(fixedWidthValueParse)
              handleProcessFile(content);
            } else {
              if (skipRows !== 0) {
                const lines = content.replace(/\r\n/g, '').split('\n');
                const contentWithoutFirstLines = lines.slice(skipRows).join('\n');
                handleProcessFile(contentWithoutFirstLines);
              } else {
              handleProcessFile(content);
            }
          }
          };
          readerTxt.readAsText(file, encodingValue);
        } else if (extension === "XLSX" || extension === "XLS") {
          const data = await file.arrayBuffer();
          const workbook = XLSX.read(data, { sheetRows: 10 });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          content = XLSX.utils.sheet_to_csv(worksheet);
          const firstLine = content.split('\n')[0].trim();
          const headers = firstLine.split(delimiter !== "" ? delimiter : ",");
          const hasDuplicateHeaders = checkHeaders(headers);
        
          if (hasDuplicateHeaders) {
            const contentWithoutFirstLine = content.replace(firstLine + '\n', '');
            setHasHeader(false);
            setSkipRows(1);
            handleProcessFile(contentWithoutFirstLine, hasDuplicateHeaders);
          } else {
            if (skipRows !== 0) {
              const lines = content.replace(/\r\n/g, '').split('\n');
              const contentWithoutFirstLines = lines.slice(skipRows).join('\n');
              handleProcessFile(contentWithoutFirstLines, hasDuplicateHeaders);
            } else {
              handleProcessFile(content, hasDuplicateHeaders);
            }
          }
        } else {
          setInProgressMessage("");
          setErrorMessage(t("Unsupported_file_extension"));
        }
      } catch (error) {
        setInProgressMessage("");
        setErrorMessage(t("An_error_has_occurred_while_processing_the_file"));
        console.error(t("An_error_has_occurred_while_processing_the_file"), error);
      }
    };
  
    readerEncoding.readAsArrayBuffer(file);
  };
  
  return (
    <div>
      {fixedFieldSize && !confirmFixedFieldSettings  ? (
        <SettingFixedWidthFields
          fixedFieldSizeColumnObject={fixedFieldSizeColumnObject}
          setFixedFieldSizeColumnObject={setFixedFieldSizeColumnObject}
          setConfirmFixedFieldSettings={setConfirmFixedFieldSettings}
          hasHeader={hasHeader}
          formatName={formatName}
        />
      ) : null}
      {fixedFieldSize && confirmFixedFieldSettings ? (
        <>
          <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
          <input
            onChange={handleInputFileRepository}
            type="file"
            name="file_repository_create_format"
            id="file_repository_create_format"
            required
            placeholder={t("File_name")}
            accept={inputFileRepositoryAcceptExtension}
            className="form-control mt-3"
          />
          {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-danger mt-4 fw-bold center">{errorMessage}</div></div>}
          {inProgressMessage && <div className="d-flex justify-content-center"><div className=" alert alert-primary mt-4 fw-bold center">{inProgressMessage}</div></div>}
        </>
      ) : fixedFieldSize ? null : (
        <>
          <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
          <input
            onChange={handleInputFileRepository}
            type="file"
            name="file_repository_create_format"
            id="file_repository_create_format"
            required
            placeholder={t("File_name")}
            accept={inputFileRepositoryAcceptExtension}
            className="form-control mt-3"
          />
          {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-danger mt-4 fw-bold center">{errorMessage}</div></div>}
          {inProgressMessage && <div className="d-flex justify-content-center"><div className=" alert alert-primary mt-4 fw-bold center">{inProgressMessage}</div></div>}
        </>
      )}
    </div>
  );
};

export default FileRepository;
